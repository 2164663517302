import React from 'react';

const CountStars = ({ margeRightContainer = "12px", value = 5, sizeWidth = "26px", sizeHeight = "26px" }) => {
    const stars = [0, 1, 2, 3, 4];
    let val = [];
    const transformVlaueToArray = () => {
        let result = [];
        for (let i = 0; i < Math.floor(value); i++) {
            result.push(i)
        }
        val = result;
    }

    transformVlaueToArray();

    const getDecimalPart = (num, idx) => {
        if (Number.isInteger(num) || idx >= val.length + 1) {
            return false;
        }
        const decimalPart = num.toString().split('.')[1];
        return true;
    }

    return (
        <div className="d-f ai-c">
            <ul className="ul d-f" style={{ marginRight: margeRightContainer }} >
                {
                    stars.map(element => (
                        <li key={element} className="li" style={{ width: sizeWidth, height: sizeHeight, marginRight: '2px' }}>
                            <span className={val[element] !== undefined ? "icon-stars-full" :
                                getDecimalPart(value, element) ? "icon-stars-half" : "icon-stars-empty"}></span>
                        </li>
                    ))
                }
            </ul>
            <p className="fs-14 lh-24 ff-gm mb-0 mr-3">{value}/5</p>
        </div>
    );
};

export default CountStars;